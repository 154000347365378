import {
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TrackableContainersLayout from '../trackableContainersLayout/TrackableContainersLayout';
import {
  Box,
  Grid,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Map, Source, Layer, Popup, MapRef } from 'react-map-gl';

import {
  clusterCountLayer,
  unclusteredPointLayer,
  clusteredPointLayer,
} from './layers';

import type { LngLatLike, ViewStateChangeEvent } from 'react-map-gl';
import type { FeatureCollection } from 'geojson';
import { CSButton, CSTextField } from '../primitives';
import Search from '@material-ui/icons/Search';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import ContainerCard from './components/containerCard/ContainerCard';
import MapboxService from '../../services/Mapbox.service';
import TrackableContainersService from '../../services/TrackableContainers.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { initialMapViewWholeUS } from './constants';
import MapTooltip from './components/mapTooltip/MapTooltip';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import { Helmet } from 'react-helmet';
import {
  TrackableContainer,
  TrackableContainerMap,
} from 'cloudsort-client';
import { FeatureProperties, LatLngMapboxAlike } from './types';
import { getNormalizedBounds } from './utils';
import { useMap } from './hooks/useMap';
import browserHistory from '../../utils/browserHistory';
import { TrackableContainersRoutes } from '../../interfaces/routes';

// Icons
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';

interface Props {
  classes: { [key: string]: string };
}

const TrackableContainersMap = ({ classes }: Props) => {
  const mapRef = useRef<MapRef>(null);
  const [mapViewBounds, setMapViewBounds] =
    useState<mapboxgl.LngLatBounds>();
  const [mapZoomLevel, setMapZoomLevel] = useState<number>(10);

  const { getPaddedBounds, fitMapBounds, flyToCoordinate } =
    useMap(mapRef);

  const [pointsOnMap, setPointsOnMap] = useState<
    TrackableContainerMap[]
  >([]);

  const [containersOnList, setContainersOnList] = useState<
    TrackableContainer[]
  >([]);

  const [listPage, setListPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<number>(0);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContainer, setTooltipContainer] =
    useState<TrackableContainer>();

  const [searchString, setSearchString] = useState<string>('');
  const [showCurrentLocationButton, setShowCurrentLocationButton] =
    useState(false);
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);

  const [isUpdatingBounds, setIsUpdatingBounds] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e));
  };

  const geojson: FeatureCollection | undefined = useMemo(() => {
    if (pointsOnMap)
      return {
        type: 'FeatureCollection',
        features: pointsOnMap.map((point) => {
          return {
            type: 'Feature',
            properties: {
              id: point.id,
              count: point.count,
              ll: point.left_lower,
              ru: point.right_upper,
            },
            geometry: {
              type: 'Point',
              coordinates: [point.lng || 0, point.lat || 0],
            },
          };
        }),
      };
    return undefined;
  }, [pointsOnMap]);

  const fetchListData = async (
    page: number,
    mapBounds?: LatLngMapboxAlike,
  ) => {
    try {
      setShowProgress(true);
      setError('');

      const bounds = mapBounds || mapViewBounds;

      if (!bounds) return;

      const containersResults = (
        await TrackableContainersService.getAll({
          page,
          pageSize: 10,
          cornerLl: `${bounds._sw.lat},${bounds._sw.lng}`,
          cornerRu: `${bounds._ne.lat},${bounds._ne.lng}`,
        })
      ).data;

      setListTotal(containersResults.count);
      setListPage(page);

      if (page === 1) {
        setContainersOnList(containersResults.results);
      } else {
        setContainersOnList([
          ...containersOnList,
          ...containersResults.results,
        ]);
      }
      setShowProgress(false);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const getAndSetTooltipContainer = async (id: number) => {
    const container = (await TrackableContainersService.getById(id))
      .data;

    if (container.id) {
      setTooltipContainer(container);
      setShowTooltip(true);
    }
  };

  const onMapClick = (event: mapboxgl.MapLayerMouseEvent) => {
    const feature = event.features?.length ? event.features[0] : null;
    if (feature && feature?.layer.id === 'cluster') {
      const { ll, ru } = feature.properties as FeatureProperties;

      if (ll && ru) {
        const splitLl = ll.split(',').map(Number);
        const splitRu = ru?.split(',').map(Number);

        if (splitLl.length === 2 && splitRu.length === 2)
          fitMapBounds(
            splitRu.reverse() as LngLatLike,
            splitLl.reverse() as LngLatLike,
            100,
          );
      }
    } else if (
      feature &&
      feature.properties &&
      feature.layer.id === 'single-container'
    ) {
      const clickedContainer = containersOnList?.find(
        (container) => container.id === feature.properties?.id,
      );

      if (clickedContainer) {
        setTooltipContainer(clickedContainer);
        setShowTooltip(true);
      } else {
        getAndSetTooltipContainer(feature.properties.id);
      }
    } else {
      //hide active tooltip
      setTooltipContainer(undefined);
      setShowTooltip(false);
    }
  };

  const fetchMapDataForBounds = async (bounds: LatLngMapboxAlike) => {
    try {
      const res = await TrackableContainersService.getMapPoints({
        cornerLl: `${bounds._sw.lat},${bounds._sw.lng}`,
        cornerRu: `${bounds._ne.lat},${bounds._ne.lng}`,
      });

      setPointsOnMap(res.data as TrackableContainerMap[]); //TODO: Fix Casting after Swagger is updated
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const updateMapViewBounds = (event?: ViewStateChangeEvent) => {
    setError('');
    const mapBounds = mapRef.current?.getBounds();

    if (mapBounds && mapRef.current) {
      const paddedBounds = getPaddedBounds(mapBounds);
      if (paddedBounds) {
        const normalizedBounds = getNormalizedBounds(paddedBounds);

        fetchMapDataForBounds(normalizedBounds);
        fetchListData(1, normalizedBounds);
      }
    }
    if (event) {
      setMapZoomLevel(event.viewState.zoom);
    }

    setMapViewBounds(mapBounds);
    setIsUpdatingBounds(false);
  };

  const resetMapState = () => {
    flyToCoordinate(
      initialMapViewWholeUS.longitude,
      initialMapViewWholeUS.latitude,
      initialMapViewWholeUS.zoom,
    );
  };

  const onGetCurrenLoacation = () => {
    if (navigator.geolocation) {
      setShowProgress(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setShowProgress(false);

          flyToCoordinate(
            position.coords.longitude,
            position.coords.latitude,
          );
        },
        () => {
          setShowProgress(false);
          setLocationPermissionDenied(true);
          alert('Permission to get location was denied');
        },
      );
    }
    setShowCurrentLocationButton(false);
  };

  const processSearch = async (e: FormEvent) => {
    e.preventDefault();
    setShowCurrentLocationButton(false);
    setShowTooltip(false);
    setTooltipContainer(undefined);
    setError('');

    if (!searchString) {
      resetMapState();
      return;
    }

    const findIdOnTheList = containersOnList?.find((container) =>
      container.identifier
        ?.toLowerCase()
        .includes(searchString.toLowerCase()),
    );

    const searchById = findIdOnTheList
      ? findIdOnTheList
      : (
          await TrackableContainersService.getAll({
            page: 1,
            pageSize: 1,
            assetId: searchString,
          })
        ).data.results[0];

    if (
      searchById?.tracker_device &&
      searchById.tracker_device.last_location_long &&
      searchById.tracker_device.last_location_lat
    ) {
      flyToCoordinate(
        searchById.tracker_device.last_location_long,
        searchById.tracker_device.last_location_lat,
      );
      setTooltipContainer(searchById);
      setShowTooltip(true);
    } else {
      // try to find geo location for the text
      setShowProgress(true);
      MapboxService.getCeocodingData({ search: searchString })
        .then((res) => {
          const { features } = res.data;
          if (features.length > 0 && features[0].relevance > 0.5) {
            const { bbox, center } = features[0];

            if (bbox && bbox.length === 4) {
              fitMapBounds(
                [bbox[0], bbox[1]], //sw
                [bbox[2], bbox[3]], //ne
              );
            } else if (center && center.length === 2) {
              flyToCoordinate(center[0], center[1]);
            } else {
              setError('There are no results for your search query');
            }
          } else {
            setError('There are no results for your search query');
          }
        })
        .catch((e) => {
          handleError(e as AxiosError);
        })
        .finally(() => {
          setShowProgress(false);
        });
    }
  };

  const setInitialMapBounds = () => {
    const mapBounds = mapRef.current?.getBounds();
    if (mapBounds) {
      updateMapViewBounds();
    } else {
      // Try again in 2s
      setTimeout(() => {
        setInitialMapBounds();
      }, 2000);
    }
  };

  useEffect(() => {
    setInitialMapBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectContainerFromTheList = (
    container: TrackableContainer,
  ) => {
    setTooltipContainer(container);

    if (mapZoomLevel < 10)
      flyToCoordinate(
        container.tracker_device?.last_location_long!,
        container.tracker_device?.last_location_lat!,
      );

    setShowTooltip(true);
  };

  return (
    <TrackableContainersLayout>
      <Helmet>
        <title>CloudSort - Trackable Containers Map</title>
      </Helmet>
      {showProgress && <ProgressIndicator />}
      <Grid container>
        <Grid item sm={4} xs={12} className={classes.padding20}>
          <Grid container className={classes.containerLeftPane}>
            <Grid item xs={12}>
              {error && (
                <CSDialogAlert
                  bottomMargin={20}
                  alertMessage={error}
                />
              )}
              <Box className={classes.textFieldContainer}>
                <form onSubmit={processSearch}>
                  <CSTextField
                    placeholder='Search Container ID, State, City'
                    variant='outlined'
                    containerSize='fullHorizontal'
                    startAdornment={
                      <Search className={classes.marginRight10} />
                    }
                    className={classes.searchField}
                    onClick={() => {
                      setShowCurrentLocationButton(true);
                    }}
                    autoComplete='off'
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                      setShowCurrentLocationButton(false);
                    }}
                  />
                </form>
                {showCurrentLocationButton &&
                  !locationPermissionDenied && (
                    <CSButton
                      variant='text'
                      className={classes.currentLocationButton}
                      onClick={onGetCurrenLoacation}
                      startIcon={<MyLocationIcon />}
                    >
                      Current Location
                    </CSButton>
                  )}
              </Box>
              {listTotal > 0 ? (
                <Typography
                  variant='h6'
                  className={classes.marginTop20}
                >
                  {listTotal} container{listTotal > 1 ? 's' : ''}
                </Typography>
              ) : (
                <>
                  <Typography
                    variant='h4'
                    className={classes.marginTop20}
                  >
                    No matching results
                  </Typography>
                  <Typography
                    variant='body1'
                    className={classes.marginTop20}
                  >
                    Try to adjust your search.
                  </Typography>

                  <CSButton
                    variant='contained'
                    color='secondary'
                    onClick={resetMapState}
                    className={classes.marginTop20}
                  >
                    Reset map state
                  </CSButton>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.containerLeftPaneGrow}
            >
              <Box className={classes.containersList}>
                {containersOnList?.map((container, i) => (
                  <ContainerCard
                    key={container.id}
                    container={container}
                    isUpdating={isUpdatingBounds}
                    selected={
                      showTooltip &&
                      tooltipContainer?.id === container.id
                    }
                    onSelect={selectContainerFromTheList}
                  />
                ))}
                {listTotal > containersOnList.length && (
                  <Box
                    my={4}
                    width='100%'
                    display='flex'
                    justifyContent='center'
                  >
                    <CSButton
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        fetchListData(listPage + 1);
                      }}
                    >
                      Load more
                    </CSButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8} xs={12} className={classes.relative}>
          <Box className={classes.absoluteTopRight}>
            <CSButton
              variant='contained'
              color='secondary'
              onClick={() => {
                browserHistory.push(TrackableContainersRoutes.LIST);
              }}
              startIcon={<ListOutlinedIcon />}
            >
              List View
            </CSButton>
            <CSButton
              variant='outlined'
              color={{
                buttonColor: 'secondary',
                iconColor: 'primary',
              }}
              style={{ marginLeft: 15 }}
              className={classes.mapButton}
              onClick={() => {
                browserHistory.push(TrackableContainersRoutes.MAP);
              }}
              startIcon={<MapOutlinedIcon />}
            >
              Map View
            </CSButton>
          </Box>
          <Map
            reuseMaps
            mapboxAccessToken={
              process.env.REACT_APP_MAPBOX_API_KEY || ''
            }
            mapLib={import('mapbox-gl')}
            initialViewState={initialMapViewWholeUS}
            minZoom={2.5}
            style={{ width: '100%', height: 'calc(100vh - 68px)' }}
            mapStyle={process.env.REACT_APP_MAPBOX_STYLE || ''}
            interactiveLayerIds={[
              clusteredPointLayer.id!,
              unclusteredPointLayer.id!,
            ]}
            pitchWithRotate={false}
            dragRotate={false}
            touchZoomRotate={false}
            onClick={onMapClick}
            onDragStart={() => {
              setIsUpdatingBounds(true);
            }}
            onDragEnd={updateMapViewBounds}
            onZoomStart={() => {
              setIsUpdatingBounds(true);
            }}
            onZoomEnd={updateMapViewBounds}
            ref={mapRef}
          >
            {pointsOnMap && (
              <Source id='containers' type='geojson' data={geojson}>
                <Layer {...clusteredPointLayer} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredPointLayer} />
              </Source>
            )}

            {showTooltip && tooltipContainer && (
              <Popup
                closeButton={false}
                className={classes.popUpClass}
                onClose={() => {
                  setTooltipContainer(undefined);
                  setShowTooltip(false);
                }}
                closeOnClick={false}
                longitude={
                  tooltipContainer?.tracker_device
                    ?.last_location_long || 0
                }
                latitude={
                  tooltipContainer?.tracker_device
                    ?.last_location_lat || 0
                }
              >
                <MapTooltip container={tooltipContainer} />
              </Popup>
            )}
          </Map>
        </Grid>
      </Grid>
    </TrackableContainersLayout>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    textFieldContainer: {
      position: 'relative',
    },
    searchField: {
      backgroundColor: theme.palette.common.white,
    },
    containerLeftPane: {
      height: 'calc(100vh - 100px)',
      flexDirection: 'row',
    },
    containerLeftPaneGrow: {
      flexGrow: 1,
    },
    containersList: {
      overflowY: 'scroll',
      height: 'calc(100vh - 200px)',
    },
    currentLocationButton: {
      position: 'absolute',
      left: 0,
      bottom: -36,
      width: '100%',
      backgroundColor: theme.palette.common.white,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    popUpClass: {
      '&>.mapboxgl-popup-content': {
        borderRadius: theme.shape.borderRadius_sm,
        boxShadow: theme.shadows[2],
      },
    },
    marginTop20: {
      marginTop: 20,
    },
    marginTop10: {
      marginTop: 10,
    },
    padding20: {
      padding: 20,
    },

    relative: {
      position: 'relative',
    },
    absoluteTopRight: {
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 10,
    },
    mapButton: {
      backgroundColor: '#f1f1f1',
    },
  })),
)(TrackableContainersMap);
