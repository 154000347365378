import { combineReducers } from '@reduxjs/toolkit';
import navigationReducer from './navigationSlice';
import filtersReducer from './filtersSlice';
import searchReducer from './searchSlice';

const rootReducer = combineReducers({
  navigation: navigationReducer,
  filters: filtersReducer,
  search: searchReducer,
});

export default rootReducer;
